import React, { useEffect, useState, useCallback } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import { Loading } from "../components/Loading";
import { useGetProjectStatusCounts } from "../effects/Admin/getProjectStatusCounts";
import Button from "@material-ui/core/Button";
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import RefreshIcon from '@material-ui/icons/Refresh';
import { toast } from "react-toastify";
import history from "./../utils/history";

const EditsHome = (props) => {
    const [isProjectLoading, setProjectLoading] = useState(false);
    const displayErrorMessage = (message) => toast.error(message);
    const [projectRows, setProjectRows] = useState([]);

    const [projectCountResults, getProjectStatusCounts] = useGetProjectStatusCounts(displayErrorMessage, setProjectLoading);
    useEffect(() => {
        setProjectRows(projectCountResults);
    }, [projectCountResults]);

    const routeToAccount = useCallback((params) => {
        history.push("/editing/" + params.id);
    }, []);

    const routeToAllAccounts = useCallback((params) => {
        history.push("/editing/AllAccounts");
    }, []);

    const columns = [
        { field: 'name', headerName: 'Project', width: 300 },
        { field: 'in_keying_count', headerName: 'Keying', width: 115 },
        { field: 'in_eligibility_count', headerName: 'Eligibility', width: 115 },
        { field: 'in_progress_count', headerName: 'In Edits', width: 130 },
        { field: 'ready_for_output_count', headerName: 'Claims Ready For Output', width: 300 },
        {
            field: "can_run_output",
            headerName: "Run Output",
            width: 200,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                if (params.row.in_progress_count > 0) {
                    return <Button color="primary" variant="contained" onClick={() => routeToAccount(params)}> Edit Claims</Button >;
                } else {
                    return <Button color="primary" disabled variant="contained" onClick={() => routeToAccount(params)}> Edit Claims</Button >;
                }
            }
        }
    ];

    const OutputGrid = () => {
        return (
            <div>
                {projectRows && (
                    <div>
                        <div style={{ textAlign: 'right' }}>
                            <Button color="primary" variant="contained" onClick={routeToAllAccounts}>Edit All Accounts</Button>
                        </div>
                        <div style={{ display: "flex" }}>
                            <h2 style={{ flex: 1 }}>Claims Ready For Edits</h2>
                            <Tooltip title="Refresh list">
                                <IconButton aria-label="refresh list" onClick={getProjectStatusCounts}>
                                    <RefreshIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div style={{ height: 600, width: '100%' }}>
                            <DataGrid
                                rows={projectRows}
                                columns={columns}
                                disableColumnMenu
                            />
                        </div>
                    </div>
                )}
                {!projectRows && (
                    <div>
                        <label>Failed to retrieve project counts, try refreshing the page</label>
                    </div>
                )}
            </div>)
    }


    return (

        <div>
            <div>
                {isProjectLoading && (
                    <Loading />
                )}
                {!isProjectLoading && (
                    <OutputGrid />
                )}
            </div>
        </div>
    );
};

export default EditsHome;
