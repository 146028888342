import React, {useContext} from "react";
import { Router, Route, Switch}  from "react-router-dom";
import { Container } from "reactstrap";

import PrivateRoute from "./components/PrivateRoute";
import PrivateReportingRoute from "./components/PrivateReportingRoute";
import { Loading } from "./components/Loading";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Admin from "./views/Admin";
import Profile from "./views/Profile";
import { useAuth0 } from "./react-auth0-spa";
import history from "./utils/history";
import Landing from "./views/Landing";
import PrivateKeyerRoute from "./components/PrivateKeyerRoute";
import PrivateEditorRoute from "./components/PrivateEditorRoute";
import PrivateAdminRoute from "./components/PrivateAdminRoute";
import ErrorPage from "./views/Error";
import KeyingHome from "./views/KeyingHome";
import EditsHome from "./views/EditsHome";
import EligibilityHome from "./views/EligibilityHome";
import Reporting from "./views/Reporting";


// styles
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
import Keying from "./views/Keying";
import Editor from "./views/Editor";
import Eligibility from "./views/Eligibility";
initFontAwesome();

export const AppSettingsContext = React.createContext();
export const useAppSettings = () => useContext(AppSettingsContext);
const App = ({
  children,
  ...args
}) => {
  const { user,loading, isAuthenticated, userIsInRoles} = useAuth0();

  
  if (loading) {
    return <Loading />;
  }

  if(!isAuthenticated){
    return <Landing></Landing>
  }

  if (window.location.pathname == "/") {
    if (userIsInRoles(['ADMIN'])) {
        history.push('/');
    } else if (userIsInRoles(['KEYER'])) {
        history.push('/keying');
    } else if (userIsInRoles(['EDITOR'])) {
        history.push('/editing');
    } else if (userIsInRoles(['ELIGIBILITY'])) {
        history.push('/eligibility');
    } else {
        history.push('/error');
    }
  } else if (userIsInRoles(['KEYER']) && window.location.pathname.split('/')[1] == "keying") {
    history.push(window.location.pathname);
  } else if (userIsInRoles(['EDITOR']) && window.location.pathname.split('/')[1] == "editing") {
    history.push(window.location.pathname);
  } else if (userIsInRoles(['EDITOR']) && window.location.pathname.split('/')[1] == "eligibility") {
      history.push(window.location.pathname);
  } else if (userIsInRoles(['ADMIN']) && window.location.pathname.split('/')[1] == "Reporting") {
      history.push(window.location.pathname);
  } else {
    history.push('/error');
  }

  return (

    <AppSettingsContext.Provider
      value ={{
        baseAddress: process.env.REACT_APP_API_BASE_ADDRESS
      }
      }>
      <Router history={history}>
        <div id="app" className="d-flex flex-column h-100">
          <NavBar />
          <Container className="flex-grow-1 mt-4 page-container">
            <Switch>
              <PrivateAdminRoute path="/" exact component={Admin} />
              <PrivateRoute path="/profile" exact component={Profile} />
              <PrivateReportingRoute path="/reporting" exact component={Reporting} />
              <PrivateKeyerRoute path="/keying" exact component={KeyingHome} />
              <PrivateKeyerRoute path="/keying/:projectName" exact component={Keying} />
              <PrivateEditorRoute path="/editing" exact component={EditsHome} />
              <PrivateEditorRoute path="/editing/:projectName" exact component={Editor} />
              <PrivateEditorRoute path="/eligibility" exact component={EligibilityHome} />
              <PrivateEditorRoute path="/eligibility/:projectName" exact component={Eligibility} />
              <PrivateRoute path="/error" exact component={ErrorPage}/>
            </Switch>
          </Container>
          <Footer />
        </div>
      </Router>
    </AppSettingsContext.Provider>
  

  );
};

export default App;
