import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Loading } from '../components/Loading';
import ImageDisplay from '../components/ImageDisplay';
import { MatchSelect } from '../components/MatchSelect';
import { Grid, Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { useAppSettings } from '../App';
import { useAuth0 } from "../react-auth0-spa";
import './../App.css';

const Eligibility = (props) => {
    const { token } = useAuth0();
    const { baseAddress } = useAppSettings();
    const { projectName } = props.match.params
    const [counts, setCounts] = useState({});
    const tempCounts = useRef({});
    const taskQueue = useRef([]);
    const idsToIgnore = useRef([]);
    const isFetching = useRef(false);
    const [taskData, setTaskData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [noWork, setNoWork] = useState(false);
    const [errored, setErrored] = useState(false);
    const [offset, setOffset] = useState({ x: 0, y: 0 });
    const [imageUrl, setImageUrl] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [fields, setFields] = useState([]);
    const [matches, setMatches] = useState([]);
    const [project, setProject] = useState("");
    const displayErrorMessage = (message) => toast.error(message);
    const [geometry, setGeometry] = useState({
            "Left": 0.04,
            "Top": 0.12,
            "Width": 0.93,
            "Height": 0.07
    });


    var getCounts = () => {
        let fetchAddress = baseAddress + '/admin/getprojectstatuscounts/' + projectName
        if (projectName === "AllAccounts") {
            fetchAddress = baseAddress + '/admin/getprojectstatuscounts'
        }
        return fetch(fetchAddress, {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + token
            })
        })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                }
            })
            .then((result) => {
                return result[0];
            },
                (error) => {
                    console.log(error);
                    return 'error';
                })
    };

    var getTask = () => {
        let countToGet = 5;
        if (taskQueue.current) {
            countToGet = 5 - taskQueue.current.length;
        }
        let idsString = "0";
        let currentlyEmpty = taskQueue.current.some(a => a.IsQueueEmpty);
        if (idsToIgnore.current.length > 0 && !currentlyEmpty) {
            idsString = idsToIgnore.current.join(",")
        }
        let fetchAddress = baseAddress + '/task/' + projectName + '/eligibility/' + countToGet.toString() + '/' + idsString
        if (projectName === "AllAccounts") {
            fetchAddress = baseAddress + '/task/null/eligibility/' + countToGet.toString() + '/' + idsString
        }
        return fetch(fetchAddress, {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + token
            })
        })
        .then((res) => {
            if (!res.ok) {
                setTimeout(() => {
                    displayErrorMessage("failed to hit the API to fetch a new task");
                }, 1000)
            } else {
                return res.json();
            }
        })
        .then((result) => {
            return result;
        },
            (error) => {
                setTimeout(() => {
                    displayErrorMessage("failed to hit the API to fetch a new task");
                }, 1000)
                console.log(error);
                return 'error';
            })
            .catch(console.error);
    };

    const resetComponents = () => {
        console.log("resetting");
        setImageUrl(null);
        setPageNumber(1);
        setTotalPages(1);
        setFields([]);
        setMatches([]);
        setProject("");
    }

    useEffect(() => {
        fetchNewTask();
        setNextTask();
    }, []);

    useEffect(() => {
        if (isLoading) {
            const interval = setInterval(() => {
                if (taskQueue.current.length > 0) {
                    resetComponents();
                    setNextTask();
                }
            }, 500);
            return () => clearInterval(interval);
        }
    }, [isLoading]);

    const fetchNewTaskRefresh = useCallback(() => {
        taskQueue.current = [];
        setIsLoading(true);
        setTaskData(null)
        fetchNewTask();
    }, [taskData, taskQueue.current])


    const fetchNewTask = useCallback(() => {
        isFetching.current = true;
        getTask().then(value => {
            if (value === 'error') {
                setErrored(true);
            }
            if (value != undefined) {
                if (!value.IsQueueEmpty) {
                    idsToIgnore.current = idsToIgnore.current.concat(value.filter(x => x.Task).map(y => y.Task.taskid))
                    console.log("GOT TASKS: " + value.filter(x => x.Task).map(x => x.Task.taskid).join(","));
                }
                let newTaskList = taskQueue.current.concat(value);
                taskQueue.current = newTaskList;
                isFetching.current = false;
            }
        });
        getCounts().then(value => {
            tempCounts.current = value
        });
    }, [taskQueue.current, isFetching.current])

    const setNextTask = useCallback(() => {
        if (taskQueue.current.length > 0){
            let nextTask = taskQueue.current[0];
            setTaskData(nextTask);
            setCounts(tempCounts.current);
            let filteredArray = taskQueue.current.filter(item => item !== nextTask)
            taskQueue.current = filteredArray;
            let noMoreToFetch = taskQueue.current.some(a => a.IsQueueEmpty) || nextTask.IsQueueEmpty;
            if ((!taskQueue.current || taskQueue.current.length < 4) && !noMoreToFetch && !isFetching.current) {
                fetchNewTask();
            }
        } else {
            setTaskData({});
        }
    }, [taskData, taskQueue.current, tempCounts.current, isFetching.current])


    const finishTask = useCallback((dictionary) => {
        if (taskData && taskData.Task) {
            let requestBody = {
                ValueDictionary: dictionary,
                TaskId: taskData.Task.taskid,
                JobId: taskData.Task.jobid
            };
            console.log(JSON.stringify(requestBody));
            fetch(baseAddress + "/edits/finish", {
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }),
                method: 'POST',
                body: JSON.stringify(requestBody)
            }).then((res) => {
                idsToIgnore.current = idsToIgnore.current.filter(item => item !== taskData.Task.taskid)
                console.log("returned from post...");
                console.log(res);
                if (!res.ok) {
                    setTimeout(() => {
                        displayErrorMessage("failed to submit keyed field for the task: " + taskData.Task.id);
                    }, 3000);
                    console.log("failed to submit keyed field for the task: " + taskData.Task.id);
                }
            },
            (error) => {
                console.log(error);
                });
            setNextTask();
        };
    }, [taskData]);

    useEffect(() => {
        if (isLoading) {
            if (taskData && (taskData.Task || taskData.IsQueueEmpty)) {
                setIsLoading(false);
            }
        } else {
            if (isFetching.current && (!taskData || !taskData.Task)) {
                setIsLoading(true);
            }
        }
    }, [isFetching.current, taskData, isLoading, setIsLoading]);

    useEffect(() => {
        if (taskData) {
            setNoWork(taskData.IsQueueEmpty);
        }
    }, [taskData, setNoWork])

    const ref = useRef(null);

    useEffect(() => {
        if (!taskData || !taskData.FieldValues) {
            return;
        }
        if (taskData && taskData.ImageUrls) {
            setImageUrl(taskData.ImageUrls.find((x) => x.includes("_0.jpeg")));
            setTotalPages(taskData.ImageUrls.length);
            if (taskData["FieldValues"]) {
                let current_values = {}
                for (var key in taskData["FieldValues"]["Values"]) {
                    let field_name = taskData["FieldValues"]["Fields"].find(x => x.datalake_field_name == key)["eligibility_field_name"]
                    current_values[field_name] = taskData["FieldValues"]["Values"][key]["Text"]
                }
                let allMatches = [current_values]
                setMatches(allMatches.concat(taskData["FieldValues"]["Matches"]));
                setFields(taskData["FieldValues"]["Fields"]);
                setProject(taskData["Task"]["projectname"]);
            }
            if (taskData.Task.projectname === "CMS-HCFA-Data-Capture") {
                setGeometry({
                    "Left": 0.04,
                    "Top": 0.12,
                    "Width": 0.93,
                    "Height": 0.45,
                    "downscale": 1.5
                });
            } else if (taskData.Task.projectname.includes("UB")){
                setGeometry({
                    "Left": 0.02,
                    "Top": 0.07,
                    "Width": 0.96,
                    "Height": 0.66
                });
            }else {
                setGeometry({
                    "Left": 0.04,
                    "Top": 0.12,
                    "Width": 0.93,
                    "Height": 0.07
                });
            }
        }
    }, [taskData]);

    const BuildTaskDataComponents = () => {
        return <div>
            <Grid container spacing={3}>
                <Grid item xs={7}>
                    <h2>{projectName}</h2>
                    {projectName === 'AllAccounts' && taskData.Task &&
                        <div>
                        <label>{taskData.Task['projectname']}</label>
                        </div>
                    }
                </Grid>
                <Grid item xs={5}>
                    <div>
                        {
                            counts &&
                            <div>
                                <div>
                                    <label>Total claims: {counts['ready_for_output_count'] + counts['in_progress_count'] + counts['in_eligibility_count']}</label>
                                </div>
                                <div>
                                    <label>Claims to be matched: {counts['in_eligibility_count']}</label>
                                </div>
                            </div>
                        }
                        {
                            !counts &&
                            <div>
                                <label>Failed to retrieve project counts</label>
                            </div>
                        }
                    </div>
                </Grid>
                <Grid item xs={7}>
                    <div>
                    </div>
                </Grid>
                <Grid item xs={5}>
                </Grid>
                <Grid item xs={7}>
                    <ImageDisplay jpegPage={imageUrl} ref={ref} geometry={geometry} pageNumber={pageNumber} offsetObj={offset} isFieldTracking={false}></ImageDisplay>
                </Grid>
                <Grid item xs={5}>
                    <div>
                        <label>Page {pageNumber} of {totalPages}</label>
                    </div>
                    <div>
                        <label>Image Name: {taskData.OriginalFileName}</label>
                    </div>
                </Grid>
                <Grid item xs={7}>
                    <MatchSelect matches={matches} fields={fields} project={project} finishTask={finishTask}></MatchSelect>
                </Grid>
                <Grid item xs={5}>
                </Grid>
            </Grid>
        </div>
    }

    const EmptyQueueComponents = () => {
        return (<Grid container justify='center'>
            <Grid item>
                <Button onClick={fetchNewTaskRefresh}>
                    <div>No Work. Click To Refresh</div>
                    <FontAwesomeIcon icon="sync" />
                </Button>
            </Grid>
        </Grid>)
    }

    const TaskDisplay = () => {
        var body = null;
        if (noWork || errored) {
            body = EmptyQueueComponents();
        } else {
            body = BuildTaskDataComponents();
        }
        return <div>
            {body}
            <ToastContainer position={toast.POSITION.BOTTOM_CENTER} type={toast.TYPE.ERROR} />
        </div>
    };
    return !isLoading && taskData ? <TaskDisplay /> : <Loading />;
};

export default Eligibility;
