import React, { useEffect, useState, useCallback} from 'react';
import { Card, CardContent, Typography} from '@material-ui/core';
import {useStyles} from '../effects/useStyles';


export function useOffsetHandler(ref, selectedField, taskData, key){
    const classes = useStyles();
    const[offset, setOffset] = useState({x:0, y:0});
    const[isOffsetMode, setIsOffsetMode] = useState(false);
    const resetOffset = useCallback(() => {
        setOffset({x:0, y:0});
    }, [setOffset]);
    const onDown = useCallback((event) => {
        if(event.key === key){
            console.log('offset button pressed: '+ isOffsetMode);
            if(isOffsetMode && ref.current){
                console.log('calculating offset for file');
                setOffset(ref.current.getOffset(selectedField.Geometry.BoundingBox, offset));
                setIsOffsetMode(false);
            }else{
                console.log('tracking');
                setIsOffsetMode(true);
            }
        }
    },[isOffsetMode, ref, selectedField, key, offset]);

    const OffsetPrompt = () =>{
        return (<Card className={classes.promptCard} hidden={!isOffsetMode}>
                <CardContent>
                    <Typography className={classes.prompt}>
                        OFFSET MODE IS ENABLED - ALIGN THE FIELD AND PRESS {key} TO LOCK IT
                    </Typography></CardContent>
            </Card>)
    }
    
    useEffect(() => {
        document.addEventListener("keydown", onDown)
        return () => {
            document.removeEventListener("keydown", onDown)
        }
    }, [onDown, isOffsetMode]);

    useEffect(() =>{
        resetOffset();
    }, [taskData, resetOffset]);

    return [offset, isOffsetMode, OffsetPrompt];
}