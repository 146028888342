import React from "react";
import CopyrightIcon from '@material-ui/icons/Copyright';
import moment from 'moment';

const Footer = () => (
  <footer className="bg-dark p-3 text-center footer">
    <div className="disclaimer-text">
        <span>Copyright</span>
        <CopyrightIcon/>
        <span>{moment().year()} </span>
        <span>OnCourse Information Services </span>
        <span>All rights reserved </span>
    </div>
  </footer>
);

export default Footer;
