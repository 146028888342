import { useEffect, useState, useCallback } from 'react';
import { useAppSettings } from '../../App';
import { useAuth0 } from "../../react-auth0-spa";

export const useGetAlerts = (displayErrorMessage, setIsLoading) => {
    const { token } = useAuth0();
    const { baseAddress } = useAppSettings();
    const [shouldGetAlerts, setShouldGetAlerts] = useState(true)
    const [results, setResults] = useState(null)
    const [showLoading, setShowLoading] = useState(true)
    const getAlerts = useCallback((hideLoading) => {
        if (hideLoading && hideLoading === true) {
            setShowLoading(false);
        } else {
            setShowLoading(true);
        }
        setShouldGetAlerts(true)
    }, [setShouldGetAlerts]);

    useEffect(() => {
        if (shouldGetAlerts === true) {
            console.log('setting is loading to true');
            setIsLoading(showLoading);
            setShouldGetAlerts(false);
            fetch(baseAddress + '/admin/getalerts', {
                method: 'GET',
                headers: new Headers({
                    'Authorization': 'Bearer ' + token
                })
            })
                .then((res) => {
                    console.log(res);
                    if (!res.ok) {
                        setTimeout(() => {
                            displayErrorMessage("failed to hit the API");
                        }, 2000)
                    } else {
                        return res.json();
                    }
                })
                .then((results) => {
                    let items = {}
                    if (results) {
                        items = results;
                        console.log('fetched alerts: ' + JSON.stringify(items));
                    } else {
                        console.log('no results');
                    }
                    setResults(items);
                },
                    (error) => {
                        console.log(error);
                    })
                .finally(() => {
                    setIsLoading(false);
                    setShowLoading(true);
                });
        };
    }, [results, shouldGetAlerts, setShouldGetAlerts, setIsLoading]);
    return [results, getAlerts];
}