import { useCallback } from 'react';
import { useAuth0 } from '../../react-auth0-spa';
import { useAppSettings } from '../../App';


export const useInvokeLambdas = () => {
    const { token } = useAuth0();
    const { baseAddress } = useAppSettings();
    const invokeLambdas = useCallback((request) => {
        console.log(JSON.stringify(request));
        return fetch(baseAddress + "/admin/invokelambdas", {
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }),
            method: 'POST',
            body: JSON.stringify(request)
        }).then(
            (res) => {
                console.log("returned from post...");
                console.log(res);
                if (!res.ok) {
                    return "failure";
                }
                return "success"
            },
            (error) => {
                return "failure";
                console.log(error);
            }
        );
    });
    return [invokeLambdas];
}
