import React, { useEffect, useCallback, useState } from 'react';
import './../App.css';

export const MatchSelect = (props) => {
    const { matches, fields, project, finishTask } = props;
    const [showNoSelection, setShowNoSelection] = useState(false);
    const [selectedMatchIndex, setSelectedMatchIndex] = useState(0);
    var MatchSelectDisplay = () => { 
        return (
            <div className={'matching-box'}>
                <div style={showNoSelection ? {} : { display: 'none' }}>
                    <p style={{ color: "red" }}>
                        Make a selection before continuing
                    </p>
                </div>
            {
                matches.map((match, index) => (
                    <div key={index} style={{ display: "flex", cursor: "pointer" }} onClick={() => onClick(index)}>
                        <div style={{ flex: 1 }}>
                            <p style={{ top: "30%", position: "relative"}}>{index}</p>
                        </div>
                        <div className={selectedMatchIndex === index ? 'selected-match' : 'unselected-match'} style={{ flex: 20 }}>
                            {project === 'Trilogy-Alliance-HCFA-Data-Capture' &&
                                trilogyMatchHtml(match)
                            }
                            {project === 'Trilogy-Alliance-UB-Data-Capture' &&
                                trilogyUBMatchHtml(match)
                            }
                            {project === 'CMS-UB-Data-Capture' &&
                                CMSUBMatchHtml(match)
                            }
                            {project === 'HPS-HCFA-Data-Capture' &&
                                hpsMatchHtml(match)
                            }
                            {project === 'CMS-HCFA-Data-Capture' &&
                                cmsMatchHtml(match)
                            }
                            {project === 'IPMG-HCFA-Data-Capture' &&
                                ipmgMatchHtml(match)
                            }
                            {project === 'IPMG-UB-Data-Capture' &&
                                ipmgUBMatchHtml(match)
                            }
                        </div>
                    </div>
                ))
            }
            </div>);
    }

    const onClick = useCallback((index) => {
        setSelectedMatchIndex(index);
    }, [selectedMatchIndex]);

    const onDown = useCallback((event) => {
        var number_pressed = parseInt(event.key);
        if (!isNaN(number_pressed)) {
            setSelectedMatchIndex(number_pressed);
        } else if (event.key === 'Enter') {
            if (selectedMatchIndex >= 0 && selectedMatchIndex < matches.length) {
                let value_dictionary = {}
                fields.forEach(x => {
                    if (x.is_transform_field) {
                        let selected_value = matches[selectedMatchIndex][x["eligibility_field_name"]]
                        value_dictionary[x["datalake_field_name"]] = { "Text": selected_value }
                    }
                });
                finishTask(value_dictionary);
            } else {
                setShowNoSelection(true);
            }
        }
        event.preventDefault();
    }, [selectedMatchIndex]);

    useEffect(() => {
        document.addEventListener("keydown", onDown)
        return () => {
            document.removeEventListener("keydown", onDown)
        }
    }, [onDown]);

    const hpsMatchHtml = function (match) {
        return <div style={{ display: "flex" }}>
            <p style={{ flex: 1 }}>{match['patient_last']}, {match['patient_first']}</p>
            <p style={{ flex: 1 }}>{match['patient_dob'].slice(0, 2) + '-' + match['patient_dob'].slice(2, 4) + '-' + match['patient_dob'].slice(4, 8)}</p>
            <p style={{ flex: 1 }}>{match['patient_ssn']}</p>
        </div>
    }

    const trilogyMatchHtml = function (match) {
        return <div style={{ display: "flex" }}>
            <p style={{ flex: 1 }}>{match['patient_last']}, {match['patient_first']}</p>
            <p style={{ flex: 1 }}>{match['patient_dob'].slice(0, 2) + '-' + match['patient_dob'].slice(2, 4) + '-' + match['patient_dob'].slice(4, 8)}</p>
            <p style={{ flex: 1 }}>{match['member_id']}</p>
            <p style={{ flex: 1 }}>{match['patient_location']}</p>
        </div>
    }

    const trilogyUBMatchHtml = function (match) {
        return <div>
            <div style={{ display: "flex" }}>
                <p style={{ flex: 1 }}>{match['patient_last']}, {match['patient_first']}</p>
                <p style={{ flex: 1 }}>{match['member_id']}</p>
                <p style={{ flex: 1 }}>{match['patient_location']}</p>
            </div>
            <div style={{ display: "flex" }}>
                <p style={{ flex: 1 }}>{match['patient_dob'].slice(0, 2) + '-' + match['patient_dob'].slice(2, 4) + '-' + match['patient_dob'].slice(4, 8)}</p>
                <p style={{ flex: 1 }}></p>
                <p style={{ flex: 1 }}></p>
            </div>
        </div>
    }

    const CMSUBMatchHtml = function (match) {
        return <div>
            <div style={{ display: "flex" }}>
                <p style={{ flex: 1 }}>{match['patient_last']}, {match['patient_first']}</p>
                <p style={{ flex: 1 }}></p>
                <p style={{ flex: 1 }}></p>
            </div>
            <div style={{ display: "flex" }}>
                <p style={{ flex: 1 }}>{match['patient_dob'].slice(0, 2) + '-' + match['patient_dob'].slice(2, 4) + '-' + match['patient_dob'].slice(4, 8)}</p>
                <p style={{ flex: 1 }}>{match['subscriber_id']}</p>
                <p style={{ flex: 1 }}>{match['workers_comp_claim_number']}</p>
                <p style={{ flex: 1 }}>{match['claimant_id']}</p>
                <p style={{ flex: 1 }}></p>
            </div>
        </div>
    }

    const ipmgMatchHtml = function (match) {
        return <div style={{ display: "flex" }}>
            <p style={{ flex: 1 }}>{match['patient_last']}, {match['patient_first']}</p>
            <p style={{ flex: 1 }}>{match['patient_dob'].slice(0, 2) + '-' + match['patient_dob'].slice(2, 4) + '-' + match['patient_dob'].slice(4, 8)}</p>
            <p style={{ flex: 1 }}>{match['patient_ssn']}</p>
        </div>
    }
    
    const ipmgUBMatchHtml = function (match) {
        return <div style={{ display: "flex" }}>
            <p style={{ flex: 1 }}>{match['patient_last']}, {match['patient_first']}</p>
            <p style={{ flex: 1 }}>{match['patient_dob'].slice(0, 2) + '-' + match['patient_dob'].slice(2, 4) + '-' + match['patient_dob'].slice(4, 8)}</p>
            <p style={{ flex: 1 }}>{match['patient_ssn']}</p>
            <p style={{ flex: 1 }}>{match['patient_group_code']}</p>
        </div>
    }

    const cmsMatchHtml = function (match) {
        return <div>
            <div style={{ display: "flex" }}>
                <p style={{ flex: 1 }}>{match['patient_last']}, {match['patient_first']}</p>
                <p style={{ flex: 1 }}>{match['patient_dob'].slice(0, 2) + '-' + match['patient_dob'].slice(2, 4) + '-' + match['patient_dob'].slice(4, 8)}</p>
                <p style={{ flex: 1 }}>{match['subscriber_id']}</p>
            </div>
            <div style={{ display: "flex" }}>
                <p style={{ flex: 1 }}></p>
                <p style={{ flex: 1 }}>{match['date_of_injury'].slice(0, 2) + '-' + match['date_of_injury'].slice(2, 4) + '-' + match['date_of_injury'].slice(4, 8)}</p>
                <p style={{ flex: 1 }}>{match['workers_comp_claim_number']}</p>
            </div>
        </div>
    }

    return MatchSelectDisplay();
}
