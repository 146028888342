import React, { useEffect, useState, useCallback } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import RefreshIcon from '@material-ui/icons/Refresh';
import { toast } from 'react-toastify';
import { Loading } from '../components/Loading';
import MUIDataTable from 'mui-datatables';
import { useAppSettings } from '../App';
import { useAuth0 } from '../react-auth0-spa';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Input,
} from '@material-ui/core';

const ReportingTable = () => {
    const { token, user, userIsInRoles, userId } = useAuth0();
    const _userId = userId();
    const { baseAddress } = useAppSettings();
    const [showLoading, setShowLoading] = useState(false);
    const displayErrorMessage = useCallback((message) => toast.error(message), []);
    const [results, setResults] = useState([]);
    const initialFilterList = [[], [], [], []];
    const [filterList, setFilterList] = useState(initialFilterList);
    const [filterOptions, setFilterOptions] = useState({});

    useEffect(() => {
        setFilterOptions((prevFilterOptions) => ({
            ...prevFilterOptions,
            3: getDistinctDates(results),
        }));
    }, [results]);

    const getDistinctDates = () => {
        return Array.from(new Set(results.map((item) => item.activity_date)))
            .sort((a, b) => new Date(b) - new Date(a));
    };

    const columns = [
        {
            name: 'project_name',
            label: 'Project',
            options: {
                filter: true,
                filterList: filterList[0],
                sort: true,
            },
        },
        {
            name: 'source_key_prefix_part',
            label: 'Type',
            options: {
                filter: true,
                filterList: filterList[1],
                sort: true,
            },
        },
        {
            name: 'full_name',
            label: 'Name',
            options: {
                filter: true,
                filterList: filterList[2],
                sort: true,
            },
        },
        {
            name: 'activity_date',
            label: 'Date',
            options: {
                filter: true,
                filterList: filterList[3],
                sort: true,
                filterType: 'custom',
                filterOptions: {
                    names: getDistinctDates(results),
                    logic: (value, filters) => {
                        if (filters.length === 0) {
                            return false;
                        }
                        const formattedFilter = new Date(filters[0]).toISOString().split('T')[0];
                        return value !== formattedFilter;
                    },
                    display: (filterList, onChange, index, column) => (
                        <FormControl key={index} fullWidth>
                            <InputLabel htmlFor={column.name}>{column.label}</InputLabel>
                            <Select
                                fullWidth
                                value={filterList[index].length ? filterList[index].toString() : 'All'}
                                name={column.name}
                                onChange={(event) => {
                                    const selectedValues = event.target.value === 'All' ? [] : [event.target.value];
                                    setFilterList((prevFilterList) => {
                                        const newFilterList = [...prevFilterList];
                                        newFilterList[index] = selectedValues;
                                        onChange(newFilterList, index, column);
                                        return newFilterList;
                                    });
                                }}
                                input={<Input name={column.name} id={column.name} />}
                            >
                                <MenuItem value="All" key={0}>
                                    All
                                </MenuItem>
                                {getDistinctDates().map((date, dateIndex) => (
                                    <MenuItem key={dateIndex + 1} value={date}>
                                        {new Date(date).toISOString().split('T')[0]}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    ),
                },
                customBodyRender: (value, tableMeta, updateValue) => {
                    const date = new Date(value);
                    return date.toISOString().split('T')[0];
                },
            },
        },
        {
            name: 'count',
            label: 'Count',
            options: {
                filter: false,
            },
        },
    ];

    const options = {
        filter: true,
        responsive: 'standard',
        print: false,
        download: false,
        selectableRowsHideCheckboxes: true,
        textLabels: {
            body: {
                noMatch: 'Sorry, no records found for user(s) in the specified time frame',
            },
        },
        onFilterChange: (column, newFilterList, type) => {
            setFilterList(newFilterList);
        },
        rowsPerPageOptions: [10, 15, 25],
    };

    const getAllRows = useCallback(() => {
        setShowLoading(true);
        fetch(`${baseAddress}/admin/getuseractivitycounts`, {
            method: 'GET',
            headers: new Headers({
                Authorization: 'Bearer ' + token,
            }),
        })
            .then((res) => {
                if (!res.ok) {
                    setTimeout(() => {
                        displayErrorMessage('Failed to hit the API');
                    }, 2000);
                } else {
                    return res.json();
                }
            })
            .then((data) => {
                if (data) {
                    setResults(data);
                    console.log('Fetched user activity counts:', JSON.stringify(data));
                } else {
                    console.log('No results');
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            })
            .finally(() => {
                setShowLoading(false);
            });
    }, [baseAddress, token, displayErrorMessage]);

    const getSpecificRows = useCallback(() => {
        setShowLoading(true);
        fetch(`${baseAddress}/admin/getuseractivitycounts/${_userId}`, {
            method: 'GET',
            headers: new Headers({
                Authorization: 'Bearer ' + token,
            }),
        })
            .then((res) => {
                if (!res.ok) {
                    setTimeout(() => {
                        displayErrorMessage('Failed to hit the API');
                    }, 2000);
                } else {
                    return res.json();
                }
            })
            .then((data) => {
                if (data) {
                    setResults(data);
                    console.log('Fetched user activity counts:', JSON.stringify(data));
                } else {
                    console.log('No results');
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            })
            .finally(() => {
                setShowLoading(false);
            });
    }, [baseAddress, token, displayErrorMessage]);

    const getRows = useCallback(() => {
        if (userIsInRoles(['ADMIN'])) {
            getAllRows();
        } else if (userIsInRoles(['KEYER','EDITOR'])) {
            getSpecificRows(_userId);
        }
    });

    useEffect(() => {
        getRows();
    }, []);

    const RowReport = () => (
        <div>
            <div style={{ display: 'flex' }}>
                <h2 style={{ flex: 1 }}>User Activity Report</h2>
                <Tooltip title="Refresh list">
                    <IconButton aria-label="refresh list" onClick={getRows}>
                        <RefreshIcon />
                    </IconButton>
                </Tooltip>
            </div>
            <div style={{ marginBottom: 10, width: '100%' }}>
                <MUIDataTable data={results} columns={columns} options={options} />
            </div>
        </div>
    );

    return (
        <div>
            <div>{showLoading ? <Loading /> : null}</div>
            <div>{!showLoading ? <RowReport /> : null}</div>
        </div>
    );
};

export default ReportingTable;