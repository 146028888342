import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";
import { useAuth0 } from "../react-auth0-spa";
import {useHistory} from "react-router-dom";


const PrivateRoute = ({ component: Component, path, ...rest }) => {
  const { isAuthenticated, loginWithRedirect,  userIsInRoles} = useAuth0();
  const history = useHistory();

  useEffect(() => {
    const fn = async () => {
      if (!isAuthenticated) {
          console.log("not authed")
        await loginWithRedirect({
          appState: { targetUrl: path }
        });
      }else if(!userIsInRoles(['KEYER'])){
          console.log("made it here")
          history.push("/error");
      }
    };
    fn();
  }, [isAuthenticated, loginWithRedirect, path, history, userIsInRoles]);

  const render = props =>
    isAuthenticated === true ? <Component {...props} /> : null;

  return <Route path={path} render={render} {...rest} />;
};

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
  path: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]).isRequired
};

export default PrivateRoute;
