import React, {useEffect, useCallback, useState} from 'react';
import {Card, CardContent, Typography} from '@material-ui/core';
import {useStyles} from '../useStyles';


export function useEditHandler(prevKey, nextImage, prevImage, rotateRightKey, rotateLeftKey, updateFieldIndex, updatePageNumber, pageNumber, totalPages, rotateFunctionRight, rotateFunctionLeft){
    const classes = useStyles();
    const bull = <span className={classes.bullet}>•</span>;
    const EditPrompt = () => <Card className={classes.promptCard} style={{ height:'100%'}}>
        <CardContent >
            <Typography className={classes.prompt}>
                <label style={{ verticalAlign: "top" }}>ENTER {bull} NEXT <br />F4 {bull} Paste Previous Field Value<br />F7 {bull} Paste Previous Claim Value<br />F9 {bull} Go To Previous Field<br /></label>
                <label style={{ paddingLeft: 60 }}>{nextImage} {bull} Next Image Page<br />{prevImage} {bull} Previous Image Page<br />{rotateLeftKey} {bull} Rotate Left<br />{rotateRightKey} {bull} Rotate Right</label>
            </Typography>
        </CardContent>
    </Card>;

    const onDown = useCallback((event) => {
        if (event.key === prevKey) {
            updateFieldIndex('prev')
            event.preventDefault();
        } else if (event.key === nextImage && pageNumber < totalPages) {
            let newPageNumber = pageNumber + 1;
            updatePageNumber(newPageNumber);
            event.preventDefault();
        } else if (event.key === prevImage && pageNumber > 1) {
            let newPageNumber = pageNumber - 1;
            updatePageNumber(newPageNumber);
            event.preventDefault();
        } else if (event.key === rotateRightKey) {
            rotateFunctionRight();
            event.preventDefault();
        } else if (event.key === rotateLeftKey) {
            rotateFunctionLeft();
            event.preventDefault();
        }
    }, [updateFieldIndex, prevKey, totalPages, pageNumber]);

    useEffect(() => {
        document.addEventListener("keydown", onDown)
        return () => {
            document.removeEventListener("keydown", onDown)
        }
    }, [onDown, updateFieldIndex]);

    return [EditPrompt];
}