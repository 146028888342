import React from "react";
import Button from '@material-ui/core/Button';
import { useAuth0 } from "../react-auth0-spa";

const innerWidth = window.innerWidth;
const innerHeight = window.innerHeight;

function Landing(){
    const { loginWithRedirect } = useAuth0();
 return (   
 <div>
     <div style={{zIndex: 999, position: 'absolute', width: '100%' }}>
         <div style={{float: 'right', padding: '60px 150px 0 0'}}>
            <Button style={{fontweight: 'bold', fontSize: 'large', color: 'black', borderColor: 'black'}} onClick={() => loginWithRedirect()} variant="outlined">Login</Button>
         </div>
     </div>
     <div style={{zIndex: 0}}>
        <img
        src={window.location.origin + "/oncourse-o-logo.png"}
        scale={0.50}
        entropy={25}
        width={innerWidth}
        height={innerHeight}
        />
     </div>
 </div>)
};

export default Landing;
