import { makeStyles } from '@material-ui/core/styles';
import { faAlignCenter, faFileExcel } from '@fortawesome/free-solid-svg-icons';

export const useStyles = makeStyles({
    root: {
      minWidth: 275,
    },
    prompt: {
      fontSize: 14,
      color: 'white'
    },
    promptCard:{
        background: '#343a40',
        paddingTop: 10,
        height:90,
        display: 'flex',
        alignItems: 'center',
        textAlign: 'left',
        justifyContent: 'center'
    },
    bullet: {
        display: 'inline-block',
        margin: '0 12px',
        transform: 'scale(2.2)',
      },
  });