import React, { useEffect, forwardRef, useImperativeHandle, useState} from 'react';
import PanZoom from "react-easy-panzoom";

const ImageDisplay = forwardRef((props, ref) => {
    let jpegPage = props.jpegPage;
    let pageNumber = props.pageNumber;
    let geometry = props.geometry;
    let offsetObj = props.offsetObj;
    let fieldTracking = props.isFieldTracking;
    let panZoom = null;
    const [error, setError] = useState(null);
    const [imageLoaded, setImageLoaded] = useState(false);

    const onPan = (event) => {
        event.preventDefault();
        if(panZoom && fieldTracking){
            ref.current.updateOverlay(event.movementX, event.movementY);
        }
    }
    
    useImperativeHandle(ref, () => ({
        updateOverlay(newleft, newtop){
            let overlayBox = document.getElementById('overlay-box');
            let overlayBoxLeft = parseInt(overlayBox.style.left.substring(0, overlayBox.style.left.length -2));
            let overlayBoxTop = parseInt(overlayBox.style.top.substring(0, overlayBox.style.top.length - 2));
            let scale = panZoom.state.scale;
            let boxleft = overlayBoxLeft - newleft * scale;//(-panZoom.state.x / panZoom.state.scale) + padding;
            let boxtop = overlayBoxTop - newtop * scale;//(-panZoom.state.y / panZoom.state.scale) + padding;
            overlayBox.style.left = boxleft +  'px';
            overlayBox.style.top = boxtop  + 'px';
        },
        getOffset(geometry, offsetObj){
            let imageDisplay = document.getElementById('document-page');
            let overlayBox = document.getElementById('overlay-box');
            console.log('adjusting offset');
            if(panZoom && overlayBox && imageDisplay){
                let docHeight = imageDisplay.offsetHeight;
                let docWidth = imageDisplay.offsetWidth;
                let pxDown = docHeight * geometry.Top;
                let pxRight = docWidth * geometry.Left;
                let overlayBoxLeft = parseInt(overlayBox.style.left.substring(0, overlayBox.style.left.length -2));
                let overlayBoxTop = parseInt(overlayBox.style.top.substring(0, overlayBox.style.top.length - 2));
                let xpixelDifference = pxRight - overlayBoxLeft;
                let ypixelDifference = pxDown - overlayBoxTop;
                console.log('x difference: ' + xpixelDifference);
                console.log('y difference: ' + ypixelDifference);
                return{
                    x: xpixelDifference,
                    y: ypixelDifference
                };
            }
        },
        rotateRight() {
            if (panZoom) {
                panZoom.rotate((startAngle) => { return startAngle + 90; })
            }
        },
        rotateLeft() {
            if (panZoom) {
                panZoom.rotate((startAngle) => { return startAngle - 90; })
            }
        }
    }));

    useEffect(() => {
        if (imageLoaded) {
            let imageDisplay = document.getElementById('document-page');
            let zoomControl = document.querySelector('.zoom-control');
            let overlayBox = document.getElementById('overlay-box');
            let scale = 3;
            let geometryToUse = geometry;
            if (pageNumber != 1) {
                geometryToUse = { Height: 1, Width: 1, Top: 0, Left: 0 };
            }
            let downscale = 1;
            if (geometryToUse.downscale) {
                downscale = geometryToUse.downscale;
            }
            if (imageDisplay && panZoom && overlayBox) {
                var pixelsWide = Math.floor((window.innerWidth - 50) / 1920 * 1000);
                pixelsWide = pixelsWide / downscale;
                zoomControl.style.width = pixelsWide.toString() + 'px';
                let docHeight = imageDisplay.offsetHeight;
                let docWidth = imageDisplay.offsetWidth;
                let boxHeight = geometryToUse.Height * docHeight;
                let boxWidth = geometryToUse.Width * docWidth;
                let widthPadding = 80;
                let heightPadding = 80;
                scale = pixelsWide / (boxWidth + (2 * widthPadding));
                if (scale > 2) {
                    scale = 2;
                    widthPadding = (pixelsWide - (2 * boxWidth)) / 4;
                }
                let windowHeight = scale * (boxHeight + (2 * heightPadding));
                zoomControl.style.height = windowHeight + 'px';
                let pxDown = docHeight * geometryToUse.Top - offsetObj.y;
                let pxRight = docWidth * geometryToUse.Left - offsetObj.x;
                let pxDownPadded = pxDown - heightPadding;
                let pxRightPadded = pxRight - widthPadding;
                overlayBox.style.height = boxHeight + 'px';
                overlayBox.style.width = boxWidth + 'px';
                overlayBox.style.top = pxDown + 'px';
                overlayBox.style.left = pxRight + 'px';
                panZoom.setState({ x: -pxRightPadded * scale, y: -pxDownPadded * scale, scale: scale });
            }
        }
    }, [geometry, offsetObj, imageLoaded]);

    return (
        <div style={{ padding: '19px', backgroundColor: 'gray' }}>
            {error
                ? <label style={{wordBreak: 'break-all' }}>{error}</label>
                :
                <PanZoom
                    className={'zoom-control'}
                    ref={Viewer => panZoom = Viewer}
                    style={{ height: 500, width: 550, border: "solid 0px black", overflow: 'hidden' }}
                    onPan={onPan}
                    keyMapping={{
                        '87': { x: 0, y: 0, z: 2 },
                        '83': { x: 0, y: 0, z: -2 }
                    }}>
                    <img id="document-page" alt='Image Not Found' src={jpegPage} onLoad={() => setImageLoaded(true)}/>
                    <div id="overlay-box" style={{ width: 200, height: 60, position: 'absolute', top: 740, left: 500, boxShadow: '0 0 0 9999px rgba(0, 0, 0, 0.35)' }}></div>
                </PanZoom>
            }
        </div>
    );
});

export default ImageDisplay;