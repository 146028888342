import React from 'react'

const ProjectCounts = (props) =>  {
    const { counts, isKeying } = props;

    const CountsDisplay = () => {
        return (<div>
            {
                counts &&
                <div>
                    <div>
                        <label>Total claims: {counts['in_keying_count'] + counts['ready_for_output_count'] + counts['in_progress_count'] + counts['in_eligibility_count']}</label>
                    </div>
                    {
                        isKeying &&
                        <div>
                            <label>Claims to be keyed: {counts['in_keying_count']}</label>
                        </div>
                    }
                    {
                        !isKeying &&
                        <div>
                            <label>Claims to be edited: {counts['in_progress_count']}</label>
                        </div>
                    }
                </div>
            }
            {
                !counts &&
                <div>
                    <label>Failed to retrieve project counts</label>
                </div>
            }
        </div>)
    };

    return <CountsDisplay />;
}

export default ProjectCounts;