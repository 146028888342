import React from 'react';
import { useEffect, useState, useCallback } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import RefreshIcon from '@material-ui/icons/Refresh';
import Button from "@material-ui/core/Button";
import { toast } from "react-toastify";
import { useGetProjectStatusCounts } from "../effects/Admin/getProjectStatusCounts";
import { useInvokeLambdas } from "../effects/Admin/invokeLambdas"
import { useGetAlerts } from "../effects/Admin/getAlerts"
import { useUpdateAlert } from "../effects/Admin/updateAlert"
import { Loading } from "../components/Loading";
import { DataGrid } from '@material-ui/data-grid';
import MUIDataTable from "mui-datatables";
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import { useAppSettings } from '../App';
import { useAuth0 } from "../react-auth0-spa";
import { DateTime } from "luxon";


const ExceptionsTable = (props) => {
    const { token } = useAuth0();
    const { baseAddress } = useAppSettings();
    const [isProjectLoading, setProjectLoading] = useState(false);
    const [isAlertsLoading, setAlertsLoading] = useState(false);
    const [alertRows, setAlertRows] = useState([]);
    const [projectRows, setProjectRows] = useState([]);
    const [filterList, setFilterList] = useState([[],[],[],['Active'],[]]);
    const [statusDict, setStatusDict] = useState({});
    const displayErrorMessage = (message) => toast.error(message);
    const [open, setOpen] = useState(false);
    const [projectNameToClear, setProjectNameToClear] = useState("");

    const [projectCountResults, getProjectStatusCounts] = useGetProjectStatusCounts(displayErrorMessage, setProjectLoading);
    const [alerts, getAlerts] = useGetAlerts(displayErrorMessage, setAlertsLoading);
    useEffect(() => {
        setAlertRows(alerts);
    }, [alerts]);

    useEffect(() => {
        let tempStatusList = projectCountResults;
        for (const [key, val] of Object.entries(statusDict)) {
            tempStatusList = tempStatusList.map(el => (el.name === key ? Object.assign(el, { action: val }) : el));
        }
        setProjectRows(tempStatusList);
    }, [projectCountResults]);

    const [invokeLambdas] = useInvokeLambdas();
    const [updateAlert] = useUpdateAlert();

    const handleOpen = useCallback((params) => {
        setOpen(true);
        setProjectNameToClear(params.id);
    });

    const handleClose = () => {
        setOpen(false);
    };

    var clearProject = (projectName) => {
        let fetchAddress = baseAddress + '/admin/clearproject/' + projectName
        return fetch(fetchAddress, {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + token
            })
        })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                }
            })
            .then((result) => {
                return result[0];
            },
                (error) => {
                    console.log(error);
                    return 'error';
                })
    };

    const handleClear = () => {
        if (projectNameToClear) {
            setProjectLoading(true);
            clearProject(projectNameToClear).then((val) => {
                if (val === "success") {
                    console.log('clear out worked')
                } else {
                    console.log('error clearing stuff out')
                };
                handleClose();
                getProjectStatusCounts(false);
            });
        }
    };

    const alertColumns = [
        {
            name: "project_name",
            label: "Project",
            options: {
                filter: true,
                filterList: filterList[0],
                sort: true
            }
        },
        {
            name: "create_time",
            label: "Alert Time",
            options: {
                filter: false,
                sort: true
            }
        },
        {
            name: "alert_message",
            label: "Error",
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: "alert_status",
            label: "Status",
            options: {
                filter: true,
                filterList: filterList[3],
                sort: true,
            }
        },
        {
            name: "Action",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    var dataTableIndex = tableMeta.currentTableData[tableMeta.rowIndex].index;
                    var row = tableMeta.tableData[dataTableIndex];
                    if (row.alert_status === 'Active') {
                        return (
                            <button value={row} onClick={() => { onResolveClick(row); }}>Resolve</button>
                        );
                    } else {
                        return (
                            <button value={row} onClick={() => { onReopenClick(row); }}>Reopen</button>
                        );
                    }
                    return;
                }
            }
        }
    ];

    const onReopenClick = (params) => {
        var request = { 'alert_id': params.id, 'status': 'Active' };
        setAlertsLoading(true);
        let invokeUpdate = updateAlert(request);
        invokeUpdate.then((val) => {
            getAlerts();
        });
    };

    const onResolveClick = (params) => {
        var request = { 'alert_id': params.id, 'status': 'Resolved' };
        setAlertsLoading(true);
        let invokeUpdate = updateAlert(request);
        invokeUpdate.then((val) => {
            getAlerts();
        });
    };

    const options = {
        print: false,
        download: false,
        selectableRowsHideCheckboxes: true,
        onFilterChange: (column, newFilterList, type) => {
            setFilterList(newFilterList);
        },
        sortOrder: {
            name: 'create_time',
            direction: 'desc'
        }
    };

    const columns = [
        { field: 'name', headerName: 'Project', width: 300 },
        { field: 'in_keying_count', headerName: 'Keying', width: 115 },
        { field: 'in_eligibility_count', headerName: 'Eligibility', width: 115 },
        { field: 'in_progress_count', headerName: 'In Edits', width: 130 },
        { field: 'reject_count', headerName: 'Rejects', width: 115 },
        { field: 'ready_for_output_count', headerName: 'Ready For Output', width: 170 },
        {
            field: "can_clear_project",
            headerName: "Clear Claims",
            width: 170,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                if (params.row.ready_for_output_count > 0 || params.row.in_progress_count > 0 || params.row.in_eligibility_count > 0 || params.row.reject_count > 0 || params.row.in_keying_count > 0) {
                    return (
                        <div height="40px">
                            <Button color="primary" variant="contained" onClick={() => handleOpen(params)}>Clear Claims</Button >
                        </div>
                    );
                } else {
                    return <div></div>;
                }
            }
        },
        {
            field: 'last_output_time', headerName: 'Last Output', width: 155, valueFormatter: (params) => {
                if (params.value == null) {
                    return '';
                }
                var date = DateTime.fromFormat(params.value, 'yyyy-MM-dd HH:mm:ss.u', { 'zone': 'UTC' });
                return date.setZone('local').toFormat('yyyy-MM-dd HH:mm:ss');
            } },
        { field: 'last_output_count', headerName: 'Output', width: 115 },
        { field: 'last_reject_count', headerName: 'Rejected', width: 115 },
        { field: 'last_duplicate_count', headerName: 'Duplicates', width: 125 }
    ];

    const OutputGrid = () => {
        return (<div>
            <div style={{display: "flex"}}>
                <h2 style={{flex: 1}}>Oncourse 2.0 Processing</h2>
                <Tooltip title="Refresh list">
                    <IconButton aria-label="refresh list" onClick={getProjectStatusCounts}>
                        <RefreshIcon />
                    </IconButton>
                </Tooltip>
            </div>
            <div style={{ height: 600, width: '100%' }}>
                <DataGrid
                    rows={projectRows}
                    columns={columns}
                    disableColumnMenu
                />
            </div>
            <Modal open={open} onClose={handleClose} >
                <div className="clear-modal">
                    <div style={{ postition: 'relative' }}>
                        <Typography variant="h6" id="modal-title">
                            Are you sure you want to clear out claims for {projectNameToClear}?
                        </Typography>
                        <div height="40px" style={{ position: 'absolute', bottom: 10, width: 'calc(100% - 40px)'}}>
                            <Button color="primary" variant="contained" onClick={() => handleClear()}>Confirm</Button >
                            <Button style={{ float: 'right' }} color="secondary" variant="contained" onClick={() => handleClose()}>Cancel</Button >
                        </div>
                    </div>
                </div>
            </Modal>
        </div>)
    }

    const AlertGrid = () => {
        return (<div>
            <div style={{ display: "flex" }}>
                <h2 style={{ flex: 1 }}>Alerts</h2>
                <Tooltip title="Refresh list">
                    <IconButton aria-label="refresh list" onClick={getAlerts}>
                        <RefreshIcon />
                    </IconButton>
                </Tooltip>
            </div>
            <div style={{ marginBottom: 10, width: '100%' }}>
                <MUIDataTable
                    data={alertRows}
                    columns={alertColumns}
                    options={options}
                />
            </div>
        </div>)
    }

    return (

        <div>
            <div>
                {isProjectLoading && (
                    <Loading />
                )}
                {!isProjectLoading && (
                    <OutputGrid />
                )}
            </div>
            <div>
                {isAlertsLoading && (
                    <Loading />
                )}
                {!isAlertsLoading && (
                    <AlertGrid/>
                )}
            </div>
        </div>
    );
};

export default ExceptionsTable;
