import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Loading } from '../components/Loading';
import { FieldPrompt } from '../components/FieldPrompt';
import ProjectCounts from '../components/ProjectCounts';
import ImageDisplay from '../components/ImageDisplay';
import { Grid, Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { pdfjs } from 'react-pdf';
import { useOffsetHandler } from '../effects/useOffsetHandler';
import { useEditHandler } from '../effects/Edits/useEditHandler';
import { useAppSettings } from '../App';
import { useAuth0 } from "../react-auth0-spa";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Keying = (props) => {
    const { token } = useAuth0();
    const { baseAddress } = useAppSettings();
    const { projectName } = props.match.params
    const [counts, setCounts] = useState({});
    const tempCounts = useRef({});
    const [taskData, setTaskData] = useState(null);
    const taskQueue = useRef([]);
    const idsToIgnore = useRef([]);
    const isFetching = useRef(false);
    const claimLastFieldNumber = useRef(0);
    const [showLoading, setShowLoading] = useState(true);
    const [noWork, setNoWork] = useState(false);
    const [errored, setErrored] = useState(false);
    const [geometry, setGeometry] = useState(false);
    const [taskToFinish, setTaskToFinish] = useState({});
    const [fieldDictionaryToFinish, setFieldDictionaryToFinish] = useState({});
    const [fieldDictionary, setFieldDictionary] = useState({});
    const [clientFieldsToEdit, setClientFieldsToEdit] = useState([]);
    const [selectedField, setSelectedField] = useState(null);
    const [selectedFieldValue, setSelectedFieldValue] = useState(null);
    const [previousValue, setPreviousValue] = useState("");
    const [fieldCount, setFieldCount] = useState(0);
    const [fieldNumber, setFieldNumber] = useState(0);
    const [fieldDirection, setFieldDirection] = useState('next');
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [imageUrl, setImageUrl] = useState(null);

    const displayErrorMessage = (message) => toast.error(message);
    const ref = useRef(null);

    const rotateLeft = () => {
        if (ref.current) {
            ref.current.rotateLeft();
        }
    }

    const rotateRight = () => {
        if (ref.current) {
            ref.current.rotateRight();
        }
    }

    var getCounts = () => {
        let fetchAddress = baseAddress + '/admin/getprojectstatuscounts/' + projectName
        if (projectName === "AllAccounts") {
            fetchAddress = baseAddress + '/admin/getprojectstatuscounts'
        }
        return fetch(fetchAddress, {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + token
            })
        })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                }
            })
            .then((result) => {
                return result[0];
            },
                (error) => {
                    console.log(error);
                    return 'error';
                })
    };

    var getTask = () => {
        let countToGet = 5;
        if (taskQueue.current) {
            countToGet = 5 - taskQueue.current.length;
        }
        let idsString = "0";
        let currentlyEmpty = taskQueue.current.some(a => a.IsQueueEmpty);
        if (idsToIgnore.current.length > 0 && !currentlyEmpty) {
            let idsInQueue = taskQueue.current.map(a => a.Task.taskid);
            if (taskData && taskData.Task) {
                idsInQueue.push(taskData.Task.taskid);
            }
            if (taskToFinish && taskToFinish.Task) {
                idsInQueue.push(taskToFinish.Task.taskid);
                console.log("tasktoFinish: " + taskToFinish.Task.taskid);
            }
            idsString = idsToIgnore.current.join(",");
        }
        console.log("fetching not including: " + idsString);
        let fetchAddress = baseAddress + '/task/' + projectName + '/key/' + countToGet.toString() + '/' + idsString
        if (projectName === "AllAccounts") {
            fetchAddress = baseAddress + '/task/null/key/' + countToGet.toString() + '/' + idsString
        }
        return fetch(fetchAddress, {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + token
            })
        })
            .then((res) => {
                if (!res.ok) {
                    setTimeout(() => {
                        displayErrorMessage("failed to hit the API to fetch a new task");
                    }, 1000)
                } else {
                    return res.json();
                }
            })
            .then((result) => {
                return result;
            },
                (error) => {
                    setTimeout(() => {
                        displayErrorMessage("failed to hit the API to fetch a new task");
                    }, 1000)
                    console.log(error);
                    return 'error';
                });
    };

    var finishTask = (finishedTask, finishedFieldDictionary) => {
        console.log("finishing task: " + finishedTask.Task.taskid);
        let requestBody = {
            ValueDictionary: finishedFieldDictionary,
            TaskId: finishedTask.Task.taskid,
            JobId: finishedTask.Task.jobid,
            Reject: finishedTask.reject ? true : false,
            ProcessingDocumentId: finishedTask.ProcessingDocumentId,
            ProcessId: finishedTask.ProcessId
        };
        fetch(baseAddress + "/edits/finish", {
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }),
            method: 'POST',
            body: JSON.stringify(requestBody)
        }).then((res) => {
            idsToIgnore.current = idsToIgnore.current.filter(item => item !== finishedTask.Task.taskid)
            if (!res.ok) {
                setTimeout(() => {
                    displayErrorMessage("failed to submit keyed field for the task: " + finishedTask.Task.taskid);
                }, 3000);
                console.log("failed to submit keyed field for the task: " + finishedTask.Task.taskid);
            }
        },
            (error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        fetchNewTask();
    }, []);

    const resetComponents = () => {
        setImageUrl(null);
        setFieldCount(0);
        setFieldDictionary({});
        setSelectedField(null);
        setFieldNumber(0);
        setPageNumber(1);
        setTotalPages(1);
    }

    useEffect(() => {
        if (showLoading) {
            const interval = setInterval(() => {
                if (taskQueue.current.length > 0) {
                    resetComponents();
                    setNextTask(false);
                }
            }, 500);
            return () => clearInterval(interval);
        }
    }, [showLoading]);

    const fetchNewTaskRefresh = useCallback(() => {
        taskQueue.current = [];
        setShowLoading(true);
        setTaskData(null)
        fetchNewTask();
    }, [taskData, taskQueue.current])

    const fetchNewTask = useCallback(() => {
        isFetching.current = true;
        getTask().then(value => {
            if (value === 'error') {
                setErrored(true);
            }
            if (!value.IsQueueEmpty) {
                idsToIgnore.current = idsToIgnore.current.concat(value.filter(x => x.Task).map(y => y.Task.taskid))
                console.log("GOT TASKS: " + value.filter(x => x.Task).map(x => x.Task.taskid).join(","));
            }
            let newTaskList = taskQueue.current.concat(value);
            taskQueue.current = newTaskList;
            isFetching.current = false;
        });
        getCounts().then(value => {
            tempCounts.current = value;
        });
    }, [taskQueue.current, isFetching.current])

    const setNextTask = useCallback((reject) => {
        if (taskToFinish && taskToFinish.Task) {
            finishTask(taskToFinish, fieldDictionaryToFinish);
        }
        if (taskData) {
            if (reject == true) {
                taskData.reject = true;
            }
            setTaskToFinish(taskData);
        }
        if (taskQueue.current.length > 0) {
            let nextTask = taskQueue.current[0];
            setTaskData(nextTask);
            setCounts(tempCounts.current);
            let filteredArray = taskQueue.current.filter(item => item !== nextTask)
            taskQueue.current = filteredArray;
            let noMoreToFetch = taskQueue.current.some(a => a.IsQueueEmpty) || nextTask.IsQueueEmpty;
            if ((!taskQueue.current || taskQueue.current.length < 4) && !noMoreToFetch && !isFetching.current) {
                fetchNewTask();
            }
        } else {
            setTaskData({});
        }
    }, [taskData, taskToFinish, fieldDictionaryToFinish, setTaskToFinish, taskQueue.current, tempCounts.current, isFetching.current])

    useEffect(() => {
        if (taskData) {
            setNoWork(taskData.IsQueueEmpty);
            if (taskData.IsQueueEmpty && taskToFinish && taskToFinish.Task) {
                finishTask(taskToFinish, fieldDictionaryToFinish);
                setTaskToFinish({});
                setFieldDictionary({});
            }
        }
    }, [taskData, setNoWork])

    useEffect(() => {
        if (selectedField) {
            setGeometry(selectedField.Geometry.BoundingBox);
        }
    }, [selectedField]);

    const updateFieldIndex = useCallback((direction) => {
        if (direction === 'next') {
            setFieldDirection(direction);
            let newFieldNumber = fieldNumber + 1;
            setFieldNumber(newFieldNumber);
        }
        if (direction === 'prev' && fieldNumber > 1) {
            setFieldDirection(direction);
            let newFieldNumber = fieldNumber - 1;
            setFieldNumber(newFieldNumber);
        }
        if (direction === 'prev' && fieldNumber == 1) {
            if (taskToFinish && taskToFinish.Task) {
                if (taskData) {
                    taskQueue.current.unshift(taskData);
                }
                claimLastFieldNumber.current = Object.keys(fieldDictionaryToFinish).length;
                setTaskData(taskToFinish);
                setTaskToFinish({});
                setFieldDictionary(fieldDictionaryToFinish);
                setFieldDictionaryToFinish({});
            }
        }
    }, [setFieldNumber, fieldNumber, fieldCount, setTaskData, setTaskToFinish, setFieldDictionary, setFieldDictionaryToFinish, taskToFinish, fieldDictionaryToFinish, taskData, claimLastFieldNumber.current]);

    const setFieldValue = useCallback((field, fieldValue) => {
        setFieldDictionary(f => {
            return {
                ...f,
                [field.FieldKey]: { 'Text': fieldValue }
            }
        });
        if (field.SkipFieldDependency) {
            let shouldSkip = field.SkipFieldDependency.SkipValue === fieldValue;
            clientFieldsToEdit.forEach(x => {
                if (field.SkipFieldDependency.FieldsToSkip.includes(x.FieldKey)) {
                    x.Skip = shouldSkip;
                }
            });
        }
        setPreviousValue(fieldValue)
        updateFieldIndex('next');
    }, [updateFieldIndex, setFieldDictionary]);

    const updatePageNumber = useCallback((newPageNumber) => {
        setPageNumber(newPageNumber);
    }, [setPageNumber, pageNumber]);

    const setFieldToEdit = () => {
        let clientFieldsValue = clientFieldsToEdit[fieldNumber - 1];
        if (!clientFieldsValue || !clientFieldsValue.FieldKey) {
            return;
        }
        if (clientFieldsValue.Skip === true) {
            updateFieldIndex(fieldDirection);
        }
        const dictionaryValue = fieldDictionary[clientFieldsValue.FieldKey].Text;
        setSelectedFieldValue(dictionaryValue);
        setSelectedField(clientFieldsValue);
    }


    useEffect(() => {
        if (fieldNumber > fieldCount && fieldCount > 0) {
            setFieldDictionaryToFinish(fieldDictionary);
            resetComponents();
            setNextTask(false);
        }
        if (fieldDictionary && imageUrl && clientFieldsToEdit && fieldNumber > 0) {
            setPageNumber(1);
            setFieldToEdit();
        }
    }, [fieldNumber]);

    useEffect(() => {
        if (claimLastFieldNumber.current > 0) {
            if (claimLastFieldNumber.current === 1) {
                setFieldToEdit();
            } else {
                setFieldNumber(claimLastFieldNumber.current);
            }
            claimLastFieldNumber.current = 0;
        } else if (imageUrl && fieldNumber < 1) {
            setFieldNumber(1);
        }
    }, [imageUrl]);

    useEffect(() => {
        if (!taskData || !taskData.ClientFields) {
            return;
        }
        let tempFields = {};
        let fieldsToEdit = [];
        for (let i = 0; i < taskData.ClientFields.length; i++) {
            let field = taskData.ClientFields[i];
            tempFields = { ...tempFields, [field.FieldKey]: { 'Text': field.Text ? field.Text : "" } };
            fieldsToEdit.push(taskData.ClientFields[i]);
        }
        setFieldDictionary(tempFields);
        setClientFieldsToEdit(fieldsToEdit);
        setFieldCount(fieldsToEdit.length);
        if (taskData && taskData.ImageUrls) {
            setTotalPages(taskData.ImageUrls.length);
            var firstPage = taskData.ImageUrls.find((x) => x.includes("_0.jpeg"));
            if (firstPage) {
                setImageUrl(firstPage);
            } else {
                setImageUrl("No Image Found");
            }
        }
    }, [taskData]);

    useEffect(() => {
        if (!taskData || !taskData.ClientFields) {
            return;
        }
        if (taskData && taskData.ImageUrls) {
            var string_page = pageNumber - 1
            var string_to_search = "_" + string_page + ".jpeg";
            setImageUrl(taskData.ImageUrls.find((x) => x.includes(string_to_search)));
        }
    }, [pageNumber]);


    const [offset, isOffsetMode, OffsetPrompt] = useOffsetHandler(ref, selectedField, taskData, 'F10');
    const [EditPrompt] = useEditHandler('F9', 'ArrowUp', 'ArrowDown', 'PageUp', 'PageDown', updateFieldIndex, updatePageNumber, pageNumber, totalPages, rotateRight, rotateLeft);

    useEffect(() => {
        if (showLoading) {
            if (taskData && (taskData.Task || taskData.IsQueueEmpty)) {
                setShowLoading(false);
            }
        } else {
            if (isFetching.current && (!taskData || !taskData.Task)) {
                setShowLoading(true);
            }
        }
    }, [isFetching.current, taskData, showLoading, setShowLoading]);

    const RejectClaim = () => {
        resetComponents();
        setNextTask(true);
    }

    const BuildTaskDataComponents = () => {
        return <div>
            <Grid container spacing={3}>
                <Grid item xs={7}>
                    <h2>{projectName}</h2>
                    {projectName === 'AllAccounts' && taskData.Task &&
                        <div>
                            <label>{taskData.Task['projectname']}</label>
                        </div>
                    }
                </Grid>
                <Grid item xs={5}>
                    <ProjectCounts counts={counts} isKeying={true}/>
                </Grid>
                <Grid item xs={7}>
                    <div>
                        <OffsetPrompt />
                        {!isOffsetMode && <EditPrompt />}
                    </div>
                </Grid>
                <Grid item xs={5}>
                    <div>
                        <label>Page {pageNumber} of {totalPages}</label>
                    </div>
                    <div>
                        <label>Image Name: {taskData.OriginalFileName}</label>
                    </div>
                    <div>
                        <label>Field {fieldNumber} of {fieldCount}</label>
                    </div>
                    <div>
                        <Button color="primary" variant="contained" type="Button" onClick={RejectClaim}> Reject Claim </Button>
                    </div>
                </Grid>
                <Grid item xs={7}>
                    <ImageDisplay jpegPage={imageUrl} ref={ref} geometry={geometry} pageNumber={pageNumber} offsetObj={offset} isFieldTracking={isOffsetMode}></ImageDisplay>
                </Grid>
                <Grid item xs={5}>
                    <FieldPrompt field={selectedField} fieldValue={selectedFieldValue} setFieldValue={setFieldValue} previousValue={previousValue} previousClaim={fieldDictionaryToFinish} updateFieldIndex={updateFieldIndex} />
                </Grid>
            </Grid>
        </div>
    }

    const EmptyQueueComponents = () => {
        return (<Grid container justify='center'>
            <Grid item>
                <Button onClick={fetchNewTaskRefresh}>
                    <div>No Work. Click To Refresh</div>
                    <FontAwesomeIcon icon="sync" />
                </Button>
            </Grid>
        </Grid>)
    }

    const TaskDisplay = () => {
        var body = null;
        if (noWork || errored) {
            body = EmptyQueueComponents();
        } else {
            body = BuildTaskDataComponents();
        }
        return <div>
            {body}
            <ToastContainer position={toast.POSITION.BOTTOM_CENTER} type={toast.TYPE.ERROR} />
        </div>
    };
    return !showLoading ? <TaskDisplay /> : <Loading />;
};

export default Keying;
