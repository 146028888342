import { useEffect, useState, useCallback } from 'react';
import { useAppSettings } from '../../App';
import { useAuth0 } from "../../react-auth0-spa";

export const useGetProjectStatusCounts = (displayErrorMessage, setIsLoading) => {
    const { token } = useAuth0();
    const { baseAddress } = useAppSettings();
    const [shouldGetProjectStatusCounts, setShouldGetProjectStatusCounts] = useState(true)
    const [results, setResults] = useState(null)
    const [showLoading, setShowLoading] = useState(true)
    const getProjectStatusCounts = useCallback((hideLoading) => {
        if (hideLoading && hideLoading === true) {
            setShowLoading(false);
        } else {
            setShowLoading(true);
        }
        setShouldGetProjectStatusCounts(true)
    }, [setShouldGetProjectStatusCounts]);

    useEffect(() => {
        if (shouldGetProjectStatusCounts === true) {
            console.log('setting is loading to true');
            setIsLoading(showLoading);
            setShouldGetProjectStatusCounts(false);
            fetch(baseAddress + '/admin/getprojectstatuscounts', {
                method: 'GET',
                headers: new Headers({
                    'Authorization': 'Bearer ' + token
                })
            })
                .then((res) => {
                    console.log(res);
                    if (!res.ok) {
                        setTimeout(() => {
                            displayErrorMessage("failed to hit the API");
                        }, 2000)
                    } else {
                        return res.json();
                    }
                })
                .then((results) => {
                    let items = {}
                    if (results) {
                        items = results.map(obj => ({ ...obj, id: obj.name }))
                        console.log('fetched project status counts: ' + JSON.stringify(items));
                    } else {
                        console.log('no results');
                    }
                    setResults(items);
                },
                    (error) => {
                        console.log(error);
                    })
                .finally(() => {
                    setIsLoading(false);
                    setShowLoading(true);
                });


        };
    }, [results, shouldGetProjectStatusCounts, setShouldGetProjectStatusCounts, setIsLoading]);
    return [results, getProjectStatusCounts];
}