import React, { useState, useCallback, useEffect} from 'react'
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import {usePrevious} from '../effects/usePrevious';


export const FieldPrompt = (props) => {
    const { field, fieldValue, setFieldValue, previousValue, previousClaim, updateFieldIndex, xs} = props;
    const [inputValue, setInputValue] = useState("");
    const [onKeyRegex, setOnKeyRegex] = useState(null);
    const [onEnterRegex, setOnEnterRegex] = useState(null);
    const [invalid, setInvalid] = useState(false);
    const [editStarted, setEditStarted] = useState(false);
    const prevField = usePrevious(field);
    const inputValueChanged = (event) => {
        setEditStarted(true)
        setInputValue(event.target.value);
    }


    const handleKeyPress = (event) =>{
        if(event.key === 'Enter'){
            if (field.Validation && field.Validation.AcceptableValues && field.Validation.AcceptableValues.length > 0 && inputValue) {
                if (!field.Validation.AcceptableValues.some(x => (x === inputValue))) {
                    setInvalid(true);
                    return;
                }
            }
            if (onEnterRegex && inputValue) {
                if (!inputValue.match(onEnterRegex)) {
                    setInvalid(true);
                    //event.preventDefault();
                } else {
                    setInvalid(false);
                    setFieldValue(field, inputValue);
                    updateFieldIndex('next');
                }
            } else {
                setFieldValue(field, inputValue);
                updateFieldIndex('next');
            }
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'F4') {
            setInputValue(previousValue);
            event.preventDefault();
        }
        if (event.key === 'F7') {
            if (previousClaim && field.FieldKey in previousClaim) {
                setInputValue(previousClaim[field.FieldKey].Text);
            }
            event.preventDefault();
        }
        if (event.key !== 'Enter') {
            if (onKeyRegex) {
                if (event.key !== 'Backspace' && !event.key.match(onKeyRegex)) {
                    event.preventDefault();
                }
            }
        }
    }

    const handleFocus = useCallback((event) => {
        if (!editStarted) {
            event.target.select();
        }
    }, [editStarted]);
    

    useEffect(() => {
        if (field && field !== prevField) {
            
            setInputValue(fieldValue);

            if (field.Validation && field.Validation.RegexOnKey) {
                setOnKeyRegex(new RegExp(field.Validation.RegexOnKey));
            }
            if (field.Validation && field.Validation.RegexOnEnd) {
                setOnEnterRegex(new RegExp(field.Validation.RegexOnEnd));
            }
        }
    }, [field, fieldValue, prevField]);

    const TextFieldOnly = () => {
        return (<Box xs={xs}>
            <TextField autoFocus variant="outlined" InputLabelProps={{ shrink: true, style: { fontSize: '25px' } }} size={"medium"} value={inputValue} type={"text"} onChange={inputValueChanged} onKeyDown={handleKeyDown} onKeyPress={handleKeyPress}></TextField>
        </Box>)
    }

    const TextFieldAndValidation = () => {
        return (<Box xs={xs}>
            <h5>{field.Name}</h5>
            <TextField fullWidth autoFocus variant="outlined" InputProps={{ className: "pulsate" }} error={invalid === true} InputLabelProps={{ shrink: true, style: { fontSize: '25px' } }} size={"medium"} value={inputValue} type={"text"} onChange={inputValueChanged} onKeyDown={handleKeyDown} onKeyPress={handleKeyPress} onFocus={handleFocus}></TextField>
            <label><b>Validation Failure Reason:</b> {field.Reason}</label>
            <div hidden={!field.Validation.FormatDescription || field.Validation.FormatDescription.length === 0} style={{ paddingTop: '20px' }}>
                {field.Validation.FormatDescription.split("\r\n").map(f => <div key={f}>{f}</div>)}
            </div>
            <div hidden={!field.Validation.AcceptableValues || field.Validation.AcceptableValues.length === 0} style={{ paddingTop: '20px' }}>
                <label>Acceptable Values:</label>
                {
                    field.Validation.AcceptableValues ? <ul>{field.Validation.AcceptableValues.map((value, i) => {
                        return (<li key={i}>"{value}"</li>)
                    })}
                    </ul> : <div></div>
                }
            </div>
        </Box>)
    }

    const PromptDisplay = () => {
        if (field && field.Validation) {
            return TextFieldAndValidation();
        } else {
            return TextFieldOnly();
        }
    };

    return field ? <PromptDisplay /> : <div>No Fields Selected</div>;
}