import React from "react";
import loading from "../assets/loading.svg";


export const Loading = (props) => {
    if (props["height"]) {
        let height = props["height"]
        return (
            <div className="spinner" style={{ height: "auto" }}>
                <img src={loading} alt="Loading" height={ height } />
            </div>
        )
    } else {
        return (
            <div className="spinner">
                <img src={loading} alt="Loading" />
            </div>
        )
    }
};
